import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useUser } from '../components/UserContext';

import toast, { Toaster } from 'react-hot-toast';

import dashboard_icon from '../assets/icons/dashboard.png';
import influencers_icon from '../assets/icons/influencers-icon.png';
import summary_icon from '../assets/icons/summary-icon.png';
import token_icon from '../assets/icons/token-icon.png';
import watchlist_icon from '../assets/icons/watchlist-icon.png';
import menu_icon from '../assets/icons/menu-icon.png';
import * as Icon from 'react-bootstrap-icons';
import './sidebar.css';

interface SidebarCompProps {
  collapsed: boolean;
  toggleSidebar: () => void; // Assuming no arguments are passed to this function
  activeItem: string;
  setActiveItem: (item: string) => void;
}

const SidebarComp: React.FC<SidebarCompProps> = ({ collapsed, toggleSidebar, activeItem, setActiveItem }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [showTooltip, setShowTooltip] = useState(false);


  const handleItemClick = (item: string, nav_link: string) => {
    console.log(user)
    if (user) {
      setActiveItem(item);
      navigate(nav_link)
    } else {
      toast.error('Please connect your wallat', {
        style: {
          background: 'rgba(0, 29, 255, 0.20)',
          color: '#fff'
        }
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setShowTooltip(width >= 1281 && width <= 1500);
    };

    window.addEventListener('resize', handleResize);

    // Check on initial render
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderTooltip = (props: any, text: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );


  return (
    <div className="sidebar-container">
      <Toaster />
      <div onClick={toggleSidebar} className={`collapse-menu ${collapsed ? 'menu-closed' : 'menu-opned'}`}>
        <img src={menu_icon} />
      </div>
      <Sidebar className="sidebar" collapsed={collapsed} width='300px'>

        <div className="sidebar-heading">
          {collapsed ? 'MmT' : 'MakemyTweet'}
        </div>
        {!collapsed &&
          <div className='mobile-wallet'>
            <div>
              <w3m-button />
            </div>

            <div>
              {user &&
                <div className='credit-header'>Credit : {user?.credit.toFixed(2)} MATIC</div>
              }
            </div>
          </div>
        }
        <Menu>
          <MenuItem
            active={activeItem === 'dashboard'}
            onClick={() => handleItemClick('dashboard', "/")}
            icon={<img className="sidebar-icons" src={dashboard_icon} alt="Dashboard" />}
          >
            <span className="sidebar-label">Dashboard</span>
          </MenuItem>
          <MenuItem
            active={activeItem === 'influencers'}
            onClick={() => handleItemClick('influencers', '/all-influencers')}
            icon={<img className="sidebar-icons" src={influencers_icon} alt="All Influencers" />}
          >
            <span className="sidebar-label">All Influencers</span>

          </MenuItem>
          <MenuItem
            active={activeItem === 'summary'}
            onClick={() => handleItemClick('summary', '/daily-summary')}
            icon={<img className="sidebar-icons" src={summary_icon} alt="Daily Summary" />}
          >
            <span className="sidebar-label">Daily Summary</span>
          </MenuItem>
          <MenuItem
            active={activeItem === 'new-token'}
            onClick={() => handleItemClick('new-token', '/new-token')}
            icon={<img className="sidebar-icons" src={token_icon} alt="New Tokens" />}
          >
            <span className="sidebar-label">New Tokens</span>
          </MenuItem>
          <MenuItem
            active={activeItem === 'watchlist'}
            onClick={() => handleItemClick('watchlist', '/watch-list')}
            icon={<img className="sidebar-icons" src={watchlist_icon} alt="Watchlist" />}
          >
            <span className="sidebar-label">Watchlist</span>
          </MenuItem>
          <MenuItem
            active={activeItem === 'live-feed'}
            onClick={() => handleItemClick('live-feed', '/live-feed')}
            icon={<Icon.Twitter className="sidebar-icons-1" size={20}></Icon.Twitter>}
          >
            <span className="sidebar-label">Live Feed</span>
          </MenuItem>
          {/* <MenuItem
            active={activeItem === 'my-feed'}
            onClick={() => handleItemClick('my-feed', '/my-feed')}
            icon={<Icon.Twitter size={20}></Icon.Twitter>}
          >
            <span className="sidebar-label">My Feed</span>
          </MenuItem> */}
          <MenuItem
            active={activeItem === 'profile'}
            onClick={() => handleItemClick('profile', '/profile')}
            icon={<Icon.PersonGear className='sidebar-icons-1' size={20}></Icon.PersonGear>}
          >
            <span className="sidebar-label">My Profile</span>
          </MenuItem>
          {user?.admin &&

            <MenuItem
              active={activeItem === 'ticker-list'}
              onClick={() => handleItemClick('ticker-list', '/ticker-list')}
              icon={<Icon.Coin className='sidebar-icons-1' size={20}></Icon.Coin>}
            >
              <span className="sidebar-label">Ticker List</span>
            </MenuItem>
          }
          {user?.admin &&
            <MenuItem
              active={activeItem === 'tweet-list'}
              onClick={() => handleItemClick('tweet-list', '/tweet-list')}
              icon={<Icon.TwitterX className='sidebar-icons-1' size={20}></Icon.TwitterX>}
            >
              <span className="sidebar-label">Tweet List</span>
            </MenuItem>
          }
          {user?.admin &&
            <MenuItem
              active={activeItem === 'influencer-list'}
              onClick={() => handleItemClick('influencer-list', '/influencer-list')}
              icon={<Icon.PeopleFill className='sidebar-icons-1' size={20}></Icon.PeopleFill>}
            >
              <span className="sidebar-label">Influencer List</span>
            </MenuItem>
          }
          {user?.admin &&
            <MenuItem
              active={activeItem === 'kpi'}
              onClick={() => handleItemClick('kpi', '/kpi')}
              icon={<Icon.Speedometer2 className='sidebar-icons-1' size={20}></Icon.Speedometer2>}
            >
              <span className="sidebar-label">KPI</span>
            </MenuItem>
          }
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SidebarComp;
