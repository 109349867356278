import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Modal, Table, Tabs, Tab } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import { useUser } from '../components/UserContext';
import link_icon from "../assets/icons/link_icon.png"
import no_img from "../assets/images/no-image-icon.png"
import './watchlist.css'

interface TokenOption {
    token: string;
    symbol: string;
    id: string;
    // ... other properties, if they exist
}

interface InfluencerFilterData {
    user_name: string;
    user_username: string;
    user_verified: boolean;
    user_profile_image_url: string;
    id: string;
    // ... other properties
}


const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

export const Watchlist = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const { user, addToWatchlist, removeFromWatchlist, watchlistInfluencer, watchlistToken } = useUser();
    const [key, setKey] = useState('my-influencers-list');
    const [keyForAddInfluencerModal, setKeyForAddInfluencerModal] = useState('add-new-influencer');
    const [keyForAddtokenModal, setKeyForAddtokenModal] = useState('add-new-token');
    const [showInfluencerModal, setShowInfluencerModal] = useState(false);
    const [showTokenModal, setShowTokenModal] = useState(false);
    const [influencerFilter, setInfluencerFilter] = useState<InfluencerFilterData[]>([]);
    const [tokenFilter, setTokenFilter] = useState<TokenOption[]>([]);
    const [suggestion, setSuggestion] = useState('');

    const handleInfluencerModalClose = () => setShowInfluencerModal(false);
    const handleInfluencerModalShow = () => setShowInfluencerModal(true);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredInfluencers, setFilteredInfluencers] = useState(watchlistInfluencer);

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (value) {
            const filtered = watchlistInfluencer.filter(influencer =>
                influencer.username.toLowerCase().includes(value.toLowerCase()) ||
                influencer.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredInfluencers(filtered);
        } else {
            setFilteredInfluencers(watchlistInfluencer);
        }
    };

    const [searchInfluencer, setSearchInfluencer] = useState('');


    const handleInfluencerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInfluencer(e.target.value);
    };

    // Filter influencers based on search query
    // const filteredNewInfluencers = influencerFilter.filter(influencer =>
    //     influencer.user_name.toLowerCase().includes(searchInfluencer.toLowerCase()) ||
    //     influencer.user_username.toLowerCase().includes(searchInfluencer.toLowerCase())
    // );

    const filteredNewInfluencers = influencerFilter.filter(influencer =>
        (typeof influencer.user_name === 'string' && influencer.user_name.toLowerCase().includes(searchInfluencer.toLowerCase())) ||
        (typeof influencer.user_username === 'string' && influencer.user_username.toLowerCase().includes(searchInfluencer.toLowerCase()))
    );
    
    
    // const [filteredNewInfluencers, setFilteredNewInfluencers] = useState(influencerFilter);



    const handleClearSearch = () => {
        setSearchInfluencer(''); // Clear the search input
        // setFilteredNewInfluencers(influencerFilter); // Restore the original list
    };


    const handleTokenModalClose = () => setShowTokenModal(false);
    const handleTokenModalShow = () => setShowTokenModal(true);

    const [searchTokenTerm, setSearchTokenTerm] = useState('');
    const [filteredTokens, setFilteredTokens] = useState(watchlistToken);

    const handleTokenSearch = (event: any) => {
        const value = event.target.value;
        setSearchTokenTerm(value);
        if (value) {
            const filtered = watchlistToken.filter(token =>
                token.token_symbol.toLowerCase().includes(value.toLowerCase()) ||
                token.token_name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredTokens(filtered);
        } else {
            setFilteredTokens(watchlistToken);
        }
    };

    const [searchToken, setSearchToken] = useState('');


    const handletokenSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchToken(e.target.value);
    };

    // Filter influencers based on search query
    const filteredNewToken = tokenFilter.filter(token =>
        token.token.toLowerCase().includes(searchToken.toLowerCase()) ||
        token.symbol.toLowerCase().includes(searchToken.toLowerCase())
    );

    const uniqueFilteredNewToken = Array.from(new Set(filteredNewToken.map(option => option.token)))
        .map(token => filteredNewToken.find(option => option.token === token));


    // const [filteredNewInfluencers, setFilteredNewInfluencers] = useState(influencerFilter);



    const handleClearTokenSearch = () => {
        setSearchToken(''); // Clear the search input
        // setFilteredNewInfluencers(influencerFilter); // Restore the original list
    };


    const open_influencer_detail = (id: any) => {
        navigate("/influencer-detail/" + id);
    }

    const open_token_detail = (token: any) => {
        navigate("/token-detail/" + token);
    }

    const remove_from_watchlist = async (type: string, watchlist_id: any) => {
        console.log(watchlist_id)
        // Check if the token is currently in the watchlist

        if (user?.wallet_id) {

            removeFromWatchlist(type, watchlist_id, user?.wallet_id)

        }
        // Optionally, refresh the watchlist data to reflect the update
    };

    const open_tweet_handle = (user_handle: string) => {
        window.open("https://twitter.com/" + user_handle, "_blank")
    }


    const get_all_token_filter = async (timeWindow: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=" + timeWindow, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();

                const filteredToken = data['filters']['tokens'].filter((token: any) =>
                    !watchlistToken.some(watchlistItem => watchlistItem.data === token.value)
                );

                setTokenFilter(filteredToken);


                const filteredInfluencers = data['filters']['influencer'].filter((influencer: any) =>
                    !watchlistInfluencer.some(watchlistItem => watchlistItem.data === influencer.user_username)
                );

                setInfluencerFilter(filteredInfluencers);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const handleSuggestionChange = (e: any) => {
        setSuggestion(e.target.value);
    };

    const submitSuggestion = async (type: any) => {
        if (!suggestion.trim()) {
            toast.error('Please enter a suggestion before submitting.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });

            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'watchlist/suggest?wallet_id=' + user?.wallet_id + '&type=' + type, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: suggestion }),
            });

            if (response.ok) {

                toast.success('Suggestion submitted successfully!', {
                    style: {
                        background: 'rgba(0, 29, 255, 0.20)',
                        color: '#fff'
                    }
                });

                setSuggestion(''); // Clear textarea after submission
                setShowInfluencerModal(false)
            } else {
                toast.error('Failed to submit suggestion. Please try again.', {
                    style: {
                        background: 'rgba(0, 29, 255, 0.20)',
                        color: '#fff'
                    }
                });

            }
        } catch (error) {
            console.error('Error submitting suggestion:', error);
            toast.error('An error occurred. Please try again.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
        }
    };

    useEffect(() => {
        console.log("get filter")
        get_all_token_filter('1month');
    }, []);

    useEffect(() => {

        const temp = influencerFilter.filter((influencer: any) =>
            !watchlistInfluencer.some(watchlistItem => watchlistItem.data === influencer.user_username)
        );

        setInfluencerFilter(temp);

        setFilteredInfluencers(watchlistInfluencer);
    }, [watchlistInfluencer]);


    useEffect(() => {

        const temp = tokenFilter.filter((token: any) =>
            !watchlistToken.some(watchlistItem => watchlistItem.data === token.token)
        );

        setTokenFilter(temp);

        setFilteredTokens(watchlistToken);
    }, [watchlistToken]);



    function formatNumber(num: number) {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M'; // For millions
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K'; // For thousands
        } else {
            return num; // For numbers less than 1000
        }
    }



    return (
        <div className="container-fluid">
            <Toaster />
            <section className="first-section section-padding position-relative">
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className='d-flex'>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        My Watchlist
                                    </div>

                                </div>

                            </div>
                        </Col>

                    </Row>
                </div>
            </section>

            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">

                    <Row>
                        <Col sm={12} className='mt-3'>
                            <Tabs
                                activeKey={key}
                                onSelect={(k: any) => setKey(k)}
                                id="uncontrolled-tab-example"
                                className="mb-3 watchlist-tabs"
                            >
                                <Tab eventKey="my-influencers-list" title="My Influencers List">
                                    <>
                                        <Row>
                                            <Col sm={12}>
                                                <div className={isMobile ? '' : 'd-flex'}>
                                                    <div className='me-auto'>
                                                        <div className='my-watch-list-heading mt-2 mb-3'>
                                                            My Influencers
                                                        </div>
                                                    </div>
                                                    <div className={isMobile ? 'mb-3' : 'd-flex mb-3'}>
                                                        <div className="search-container">
                                                            <input
                                                                type="text"
                                                                id="search-influencer"
                                                                className="search-input"
                                                                placeholder="Search Influencer"
                                                                onChange={handleSearch}
                                                                value={searchTerm}
                                                            />

                                                            <label htmlFor="search-influencer" className="search-icon">
                                                                <Icon.Search></Icon.Search>
                                                            </label>
                                                        </div>
                                                        <div className='add-new-watchlist' onClick={handleInfluencerModalShow}>
                                                            <Icon.Plus></Icon.Plus> Add New Influencer
                                                        </div>
                                                    </div>
                                                    <Modal className="add-new-watchlist-modal" show={showInfluencerModal} onHide={handleInfluencerModalClose}>

                                                        <Modal.Body>
                                                            <div>
                                                                <Icon.XCircle className='influencer-watchtlist-close' onClick={() => setShowInfluencerModal(false)} size={25}></Icon.XCircle>
                                                            </div>
                                                            <Tabs
                                                                activeKey={keyForAddInfluencerModal}
                                                                onSelect={(k: any) => setKeyForAddInfluencerModal(k)}
                                                                id="uncontrolled-tab-example"
                                                                className="mb-3 watchlist-tabs"
                                                            >
                                                                <Tab eventKey="add-new-influencer" title="Add New Influencer">
                                                                    <div className="search-container">
                                                                        <input
                                                                            type="text"
                                                                            id="search-add-influencer"
                                                                            className="search-input"
                                                                            placeholder="Search Influencer"
                                                                            onChange={handleInfluencerSearch}
                                                                            value={searchInfluencer}
                                                                        />

                                                                        {searchInfluencer && (
                                                                            <button onClick={handleClearSearch} className="clear-search-button">
                                                                                <Icon.XCircle></Icon.XCircle>
                                                                            </button>

                                                                        )}
                                                                        {!searchInfluencer && (
                                                                            <label htmlFor="search-add-influencer" className="search-icon">
                                                                                <Icon.Search></Icon.Search>
                                                                            </label>
                                                                        )}
                                                                    </div>
                                                                    {user &&
                                                                        <div className='watchlist-influencer-list'>
                                                                            {filteredNewInfluencers.length > 0 ? (
                                                                                filteredNewInfluencers.map((option, index) => (
                                                                                    <div className='influencer-list-item' onClick={() => addToWatchlist('Influencer', option.id, user?.wallet_id)} key={index}>
                                                                                        {option.user_username}
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div className='no-results'>No matching influencers found.</div>
                                                                            )}
                                                                        </div>
                                                                    }
                                                                </Tab>
                                                                <Tab eventKey="suggest-influencer" title="Suggest Influencer">
                                                                    <textarea
                                                                        className='suggest-watchlist'
                                                                        rows={4}
                                                                        cols={40}
                                                                        placeholder='Suggest Influencer’s Name, Account or Link'
                                                                        value={suggestion}
                                                                        onChange={handleSuggestionChange}
                                                                    ></textarea>
                                                                    <button className='suggest-watchlist-btn' onClick={() => submitSuggestion('Influencer')}>
                                                                        Submit Suggestion
                                                                    </button>
                                                                </Tab>
                                                            </Tabs>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                            <Table className='atw-table'>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Influencers Account</th>
                                                        <th>Followers</th>
                                                        <th>Tweet Link</th>

                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredInfluencers.length > 0 ? (
                                                        filteredInfluencers.map((watchlistdata, index) => (
                                                            <tr key={index}>

                                                                <td width={'10%'}>
                                                                    {index + 1}
                                                                </td>
                                                                <td width={'30%'} className='cursor-pointer' onClick={() => open_influencer_detail(watchlistdata['data'])}>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div>
                                                                            <img alt="profile" src={watchlistdata['profile_image_url']} className="t-d-user-img" />
                                                                        </div>

                                                                        <div className="ms-1 ticker-name">{watchlistdata['name']}</div>
                                                                        <div className="ms-1 ticker-symbol">@{watchlistdata['username']}</div>

                                                                    </div>


                                                                </td>

                                                                <td width={'20%'}>
                                                                    {(watchlistdata['public_metrics'] ? formatNumber(watchlistdata['public_metrics']['followers_count']) : '')}
                                                                </td>
                                                                <td width={'20%'}>
                                                                    <img alt="link-icon" className='table-link-icon' onClick={() => open_tweet_handle(watchlistdata['data'])} src={link_icon} />
                                                                </td>
                                                                <td width={'20%'}>
                                                                    <button onClick={() => remove_from_watchlist('Influencer', watchlistdata['watchlist_id'])} className='remove-watchlist-btn'>  <Icon.Trash3 size={15} className='mx-2' />Remove</button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        // Render this row if allTokenTweets is empty
                                                        <tr>
                                                            <td colSpan={5} style={{ textAlign: 'center' }}>No data available</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </Table>
                                        </div>

                                        {/* <div>
                                    
                                        {totalPages > 1 &&
                                            <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                <ul className="pagination">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href="#" aria-label="Previous">
                                                                <span aria-hidden="true">Previous</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {renderPageNumbers()}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href="#" aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        }
                                    </div> */}
                                    </>
                                </Tab>
                                <Tab eventKey="my-token-list" title="My Token List">
                                    <>
                                        <Row>
                                            <Col sm={12}>
                                                <div className={isMobile ? '' : 'd-flex'}>
                                                    <div className='me-auto'>
                                                        <div className='my-watch-list-heading mt-2 mb-3'>
                                                            My Tokens
                                                        </div>
                                                    </div>
                                                    <div className={isMobile ? 'mb-3' : 'd-flex mb-3'}>
                                                        <div className="search-container">
                                                            <input
                                                                type="text"
                                                                id="search-token"
                                                                className="search-input"
                                                                placeholder="Search Token"
                                                                onChange={handleTokenSearch}
                                                                value={searchTokenTerm}
                                                            />

                                                            <label htmlFor="search-influencer" className="search-icon">
                                                                <Icon.Search></Icon.Search>
                                                            </label>
                                                        </div>
                                                        <div className='add-new-watchlist' onClick={handleTokenModalShow}>
                                                            <Icon.Plus></Icon.Plus> Add New Token
                                                        </div>
                                                    </div>
                                                    <Modal className="add-new-watchlist-modal" show={showTokenModal} onHide={handleTokenModalClose}>

                                                        <Modal.Body>
                                                            <div>
                                                                <Icon.XCircle className='influencer-watchtlist-close' onClick={() => setShowTokenModal(false)} size={25}></Icon.XCircle>
                                                            </div>
                                                            <Tabs
                                                                activeKey={keyForAddtokenModal}
                                                                onSelect={(k: any) => setKeyForAddtokenModal(k)}
                                                                id="uncontrolled-tab-example"
                                                                className="mb-3 watchlist-tabs"
                                                            >
                                                                <Tab eventKey="add-new-token" title="Add New Token">
                                                                    <div className="search-container">
                                                                        <input
                                                                            type="text"
                                                                            id="search-add-influencer"
                                                                            className="search-input"
                                                                            placeholder="Search token"
                                                                            onChange={handletokenSearch}
                                                                            value={searchToken}
                                                                        />

                                                                        {searchToken && (
                                                                            <button onClick={handleClearTokenSearch} className="clear-search-button">
                                                                                <Icon.XCircle></Icon.XCircle>
                                                                            </button>

                                                                        )}
                                                                        {!searchToken && (
                                                                            <label htmlFor="search-add-influencer" className="search-icon">
                                                                                <Icon.Search></Icon.Search>
                                                                            </label>
                                                                        )}
                                                                    </div>
                                                                    {user &&
                                                                        <div className='watchlist-influencer-list'>
                                                                            {uniqueFilteredNewToken.length > 0 ? (
                                                                                uniqueFilteredNewToken.map((option, index) => (
                                                                                    option && (
                                                                                        <div className='influencer-list-item' onClick={() => addToWatchlist('Token', option.id, user?.wallet_id)} key={index}>
                                                                                            {option.token} ({option.symbol})
                                                                                        </div>
                                                                                    )
                                                                                ))
                                                                            ) : (
                                                                                <div className='no-results'>No matching tokens found.</div>
                                                                            )}
                                                                        </div>
                                                                    }

                                                                </Tab>
                                                                <Tab eventKey="suggest-token" title="Suggest Token">
                                                                    <textarea
                                                                        className='suggest-watchlist'
                                                                        rows={4}
                                                                        cols={40}
                                                                        placeholder='Suggest Token Name or Link'
                                                                        value={suggestion}
                                                                        onChange={handleSuggestionChange}
                                                                    ></textarea>
                                                                    <button className='suggest-watchlist-btn' onClick={() => submitSuggestion('Token')}>
                                                                        Submit Suggestion
                                                                    </button>
                                                                </Tab>
                                                            </Tabs>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                            <Table className='atw-table'>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Tokens</th>
                                                        <th>Link</th>

                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredTokens.length > 0 ? (
                                                        filteredTokens.map((watchlistdata, index) => (
                                                            <tr key={index}>

                                                                <td width={'10%'}>
                                                                    {index + 1}
                                                                </td>
                                                                <td width={'30%'} className='cursor-pointer' onClick={() => open_token_detail(watchlistdata['id'])}>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div>
                                                                            <img alt="watchlist-icon" src={watchlistdata['image_url'] ? watchlistdata['image_url'] : no_img} className="t-d-user-img" />
                                                                        </div>
                                                                        <div className="ms-1 ticker-name">{watchlistdata['token_name']}</div>
                                                                        <div className="ms-1 ticker-symbol">{watchlistdata['token_symbol']}</div>
                                                                    </div>


                                                                </td>

                                                                <td width={'20%'}>
                                                                    <img alt='link-icon' className='table-link-icon' src={link_icon} />
                                                                </td>
                                                                <td width={'20%'}>
                                                                    <button onClick={() => remove_from_watchlist('Token', watchlistdata['watchlist_id'])} className='remove-watchlist-btn'>  <Icon.Trash3 size={15} className='mx-2' />Remove</button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        // Render this row if allTokenTweets is empty
                                                        <tr>
                                                            <td colSpan={4} style={{ textAlign: 'center' }}>No data available</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </Table>
                                        </div>

                                        {/* <div>
                                    
                                        {totalPages > 1 &&
                                            <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                <ul className="pagination">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href="#" aria-label="Previous">
                                                                <span aria-hidden="true">Previous</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {renderPageNumbers()}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href="#" aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        }
                                    </div> */}
                                    </>
                                </Tab>

                            </Tabs>


                        </Col>
                    </Row>
                </div>
            </section >

        </div >
    )
}

export default Watchlist;