import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import { Row, Col, Card, Form, Table, Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { useAccount } from 'wagmi'
import TokenFilter from '../components/TokenFilter';
import EcoSystemFilter from '../components/EcosystemFilter';
import CategoryFilter from '../components/CategoryFilter';
import { transformInfluencersToCategories, transformTokensToCategories } from '../components/utils';
import { Influencer, InfluencerCategory, Token, TokenCategory } from '../components/filterTypes';
import CreatableSelect from 'react-select/creatable';
import Select, { ActionMeta, MultiValue } from 'react-select';
import Swal from 'sweetalert2';
import jsonData from '../components/jsonformatter.json';
import no_img from "../assets/images/no-image-icon.png"
import './admin.css'
import assert from 'assert';
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

interface Links {
    homepage: string;
    telegram_channel_identifier: string;
    twitter_screen_name: string;
}

interface Tokens {
    token_name: string;
    image_url?: string;
    cryptocurrency?: string;
    token_symbol?: string;
    coin_id: string;
    categories?: string[];
    ecosystem: string[];
    mmt_categories: string[],
    total_tweets: number;
    description: string;
    id: string;
    token_address: string;
    website: string;
    telegram_channel_identifier: string;
    twitter_screen_name: string;
    dexscreen_url: string;
    coingecko_url: string;
    dappradar_url: string;
    defillama_url: string;
    discord_url: string;
    tokenterminal_url: string;
    chain_id: string;
    verified: boolean;
    fetch_status: boolean;
    asset_platform_id: string;
}

interface FormData {
    token_name: string;
    coin_id: string;
    image_url: string;
    token_symbol: string;
    description: string;
    token_address: string;
    website: string;
    telegram_channel_identifier: string;
    twitter_screen_name: string;
    coingecko_url: string;
    dexscreen_url: string;
    chain_id: string;
    categories: { value: string; label: string }[];
    mmt_categories: { value: string; label: string }[];
    ecosystem: { value: string; label: string }[];
    dappradar_url: string;
    defillama_url: string;
    discord_url: string;
    tokenterminal_url: string;
    verified: boolean,
    fetch_status: boolean,
    asset_platform_id: string;
}

interface Category {
    value: string;
    label: string;
}

// Define the type for the select options
interface SelectAssertPlatformIdOption {
    value: string;
    label: string;
}

interface Ecosystem {
    name: string;
    address: string;
    dexscreen_url: string;
}




const useDebounce = (value: any, delay: any) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};



export const TickerList = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const { address, isConnected } = useAccount()

    const [allToken, setAllToken] = useState<Tokens[]>([]);
    const [allTweetloader, setAllTweetloader] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [verificationStatus, setVerificationStatus] = useState("all");
    const [fetchStatus, setFetchStatus] = useState("all");
    const [verifiedAndNotVerified, setVerifiedAndNotVerified] = useState(false);


    const [keywordFilter, setKeywordFilter] = useState<string[]>([]);
    const [tokenFilter, setTokenFilter] = useState<TokenCategory[]>([]);
    const [ecosystemFilter, setEcosystemFilter] = useState<string[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);

    const [filteredTokens, setFilteredTokens] = useState<string[]>([]);
    const [filteredKeywords, setFilteredKeywords] = useState<string[]>([]);

    const [assetPlatformIdOptions, setAssetPlatformIdOptions] = useState<SelectAssertPlatformIdOption[]>([]);

    const handleTokenSelectionChange = (selectedValues: string[]) => {
        setFilteredTokens(selectedValues)
    };

    const [showTickerUpdateModal, setShowTickerUpdateModal] = useState(false);
    const [showTickerCreateModal, setShowTickerCreateModal] = useState(false);

    const [selectedToken, setSelectedToken] = useState<Tokens | null>(null);
    const [updatedDescription, setUpdatedDescription] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [expandedDescriptions, setExpandedDescriptions] = useState<{ [key: string]: boolean }>({});
    const [categoryOptions, setCategoryOptions] = useState<Category[]>([]);
    const [ecosystemsOptions, setEcosystemsOptions] = useState<Category[]>([]);
    const [ecosystems, setEcosystems] = useState<Ecosystem[]>([]);
    const [mmtCategoriesOptions, setMmtCategoriesOptions] = useState<Category[]>([]);
    const [formData, setFormData] = useState<FormData>({
        token_name: '',
        coin_id: '',
        image_url: '',
        token_symbol: '',
        description: '',
        token_address: '',
        website: '',
        telegram_channel_identifier: '',
        twitter_screen_name: '',
        coingecko_url: '',
        dexscreen_url: '',
        chain_id: '',
        categories: [],
        mmt_categories: [],
        ecosystem: [],
        dappradar_url: '',
        defillama_url: '',
        discord_url: '',
        tokenterminal_url: '',
        verified: false,
        fetch_status: true,
        asset_platform_id: ''
    });

    const [newTokenData, setNewTokenData] = useState<FormData>({
        token_name: '',
        token_symbol: '',
        coin_id: '',
        chain_id: '',
        asset_platform_id: '',
        image_url: '',
        description: '',
        token_address: '',
        dappradar_url: '',
        defillama_url: '',
        discord_url: '',
        tokenterminal_url: '',
        website: '',
        telegram_channel_identifier: '',
        twitter_screen_name: '',
        coingecko_url: '',
        dexscreen_url: '',
        categories: [],
        mmt_categories: [],
        ecosystem: [],
        verified: false,
        fetch_status: false
    });

    const searchBarRef = useRef<HTMLDivElement>(null);
    const [searchQuery, setSearchQuery] = useState('');

    // Debounced search query
    const debouncedSearchQuery = useDebounce(searchQuery, 500);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleNewTokenChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setNewTokenData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSelectChange = (selectedOption: any) => {
        setFormData((prevState) => ({
            ...prevState,
            asset_platform_id: selectedOption.value,
        }));
    };

    const handleNewTokenSelectChange = (selectedOption: any) => {
        setNewTokenData((prevState) => ({
            ...prevState,
            asset_platform_id: selectedOption.value,
        }));
    };


    const open_token_detail = (token: any) => {
        if (isConnected) {
            window.open("/token-detail/" + token, "_blank");
            // navigate("/token-detail/" + token);
        } else {
            toast.error('Please connect your wallat', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
        }

    }


    const handleCreateNewTokenClick = () => {
        // Reset the form data
        setNewTokenData({
            token_name: '',
            token_symbol: '',
            coin_id: '',
            chain_id: '',
            asset_platform_id: '',
            image_url: '',
            description: '',
            token_address: '',
            dappradar_url: '',
            defillama_url: '',
            discord_url: '',
            tokenterminal_url: '',
            website: '',
            telegram_channel_identifier: '',
            twitter_screen_name: '',
            coingecko_url: '',
            dexscreen_url: '',
            categories: [],
            mmt_categories: [],
            ecosystem: [],
            verified: false,
            fetch_status: false
        });
        // Open the modal
        setShowTickerCreateModal(true);
    };



    const edit_token = (token: Tokens) => {
        if (!token) {
            return;
        }
        setSelectedToken(token);

        const categories = token?.categories?.map((cat: string) => ({ value: cat, label: cat })) || [];
        const ecosystems = token?.ecosystem?.map((cat: any) => ({ value: cat['name'], label: cat['name'] })) || [];

        if (token?.ecosystem) {
            const isArrayOfStrings = typeof token.ecosystem[0] === 'string';
        
            setEcosystems(token.ecosystem.map((eco: any) => {
                if (isArrayOfStrings) {
                    return {
                        name: eco,                 // Use the string as the name
                        address: '',               // Set default or fetched address here
                        dexscreen_url: ''          // Set default or fetched dexscreen_url here
                    };
                } else {
                    return {
                        name: eco.name,            // Assuming the object has a name property
                        address: eco.address,      // Assuming the object has an address property
                        dexscreen_url: eco.dexscreen_url // Assuming the object has a dexscreen_url property
                    };
                }
            }));
        } else {
            setEcosystems([]);
        }

      

        const mmt_categories = token?.mmt_categories?.map((cat: string) => ({ value: cat, label: cat })) || [];
        setFormData({
            token_name: token?.token_name || '',
            coin_id: token?.coin_id || '',
            image_url: token?.image_url || '',
            token_symbol: token?.token_symbol || '',
            description: token?.description || '',
            token_address: token?.token_address || '',
            website: token?.website || '',
            telegram_channel_identifier: token?.telegram_channel_identifier || '',
            twitter_screen_name: token?.twitter_screen_name || '',
            coingecko_url: token?.coingecko_url || '',
            dexscreen_url: token?.dexscreen_url || '',
            chain_id: token?.chain_id || '',
            categories: categories,
            ecosystem: ecosystems,
            mmt_categories: mmt_categories,
            dappradar_url: token?.dappradar_url || '',
            defillama_url: token?.defillama_url || '',
            discord_url: token?.discord_url || '',
            tokenterminal_url: token?.tokenterminal_url || '',
            verified: token?.verified || false,
            fetch_status: token?.fetch_status || false,
            asset_platform_id: token?.asset_platform_id || ''
        });

        setUpdatedDescription(token?.description || ''); // Initialize with existing description or empty
        setShowTickerUpdateModal(true);
    };


    const delete_token = (token: Tokens) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result: any) => {
            if (result.isConfirmed) {
                const url = `${process.env.REACT_APP_API_URL}/admin/ticker/${token.id}?wallet_id=${address}`;
                const headers = {
                    'Cookie': 'csrftoken=THWyvh3Mhe1B4h3HSNjcQN3zgVBkv61L'
                };

                fetch(url, {
                    method: 'DELETE',
                    headers: headers
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        Swal.fire(
                            'Deleted!',
                            'Your token has been deleted.',
                            'success'
                        );
                        get_all_token(currentPage, filteredTokens, filteredKeywords, '');
                        // Optionally, refresh your data here or remove the deleted token from the state
                    })
                    .catch((error) => {
                        console.error('Error deleting token:', error);
                        Swal.fire(
                            'Error!',
                            'There was a problem deleting your token.',
                            'error'
                        );
                    });
            }
        });
    };


    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUpdatedDescription(e.target.value);
    };

    const handleSave = () => {
        handleSaveChanges(formData);
    };


    const handleNewTokenCreate = () => {
        handleCreateNewToken(newTokenData);
    };


    const handleCategoryChange = (selectedOptions: MultiValue<Category>, actionMeta: ActionMeta<Category>) => {
        setFormData(prevState => ({
            ...prevState,
            categories: selectedOptions as Category[]
        }));
    };

    const handleNewTokenCategoryChange = (selectedOptions: MultiValue<Category>, actionMeta: ActionMeta<Category>) => {
        setNewTokenData(prevState => ({
            ...prevState,
            categories: selectedOptions as Category[]
        }));
    };

    const handleMMTCategoryChange = (selectedOptions: MultiValue<Category>, actionMeta: ActionMeta<Category>) => {
        setFormData(prevState => ({
            ...prevState,
            mmt_categories: selectedOptions as Category[]
        }));
    };

    const handleNewTokenMMTCategoryChange = (selectedOptions: MultiValue<Category>, actionMeta: ActionMeta<Category>) => {
        setNewTokenData(prevState => ({
            ...prevState,
            mmt_categories: selectedOptions as Category[]
        }));
    };

    //Old One
    // const handleEcosystemChange = (selectedOptions: MultiValue<Category>, actionMeta: ActionMeta<Category>) => {
    //     setFormData(prevState => ({
    //         ...prevState,
    //         ecosystem: selectedOptions as Category[]
    //     }));
    // };

    const handleEcosystemChange = (index: any, field: any, selectedOption: any) => {
        console.log(index, field, selectedOption.value)
        const updatedEcosystems = ecosystems.map((ecosystem, i) =>
            i === index ? { ...ecosystem, [field]: selectedOption.value } : ecosystem
        );
        setEcosystems(updatedEcosystems);
    };

    const handleNewTokenEcosystemChange = (selectedOptions: MultiValue<Category>, actionMeta: ActionMeta<Category>) => {
        setNewTokenData(prevState => ({
            ...prevState,
            ecosystem: selectedOptions as Category[]
        }));
    };

    const handleEcosystemChangeInTable = (index: any, field: any, selectedOption: any) => {
        const updatedEcosystems = ecosystems.map((ecosystem, i) =>
            i === index ? { ...ecosystem, [field]: selectedOption.value } : ecosystem
        );
        setEcosystems(updatedEcosystems);
    };

    const handleCreateAssetPlatformId = (inputValue: string) => {
        const newCategory = { value: inputValue, label: inputValue };
        setAssetPlatformIdOptions(prevOptions => [...prevOptions, newCategory]);
        setFormData((prevState) => ({
            ...prevState,
            asset_platform_id: inputValue,
        }));
    };


    const handleCreateNewTokenAssetPlatformId = (inputValue: string) => {
        const newCategory = { value: inputValue, label: inputValue };
        setAssetPlatformIdOptions(prevOptions => [...prevOptions, newCategory]);
        setNewTokenData((prevState) => ({
            ...prevState,
            asset_platform_id: inputValue,
        }));
    };

    const handleCreateCategory = (inputValue: string) => {
        const newCategory = { value: inputValue, label: inputValue };
        setCategoryOptions(prevOptions => [...prevOptions, newCategory]);
        setFormData(prevState => ({
            ...prevState,
            categories: [...prevState.categories, newCategory]
        }));
    };

    const handleCreateNewTokenCategory = (inputValue: string) => {
        const newCategory = { value: inputValue, label: inputValue };
        setCategoryOptions(prevOptions => [...prevOptions, newCategory]);
        setNewTokenData(prevState => ({
            ...prevState,
            categories: [...prevState.categories, newCategory]
        }));
    };


    const handleCreateMMTCategory = (inputValue: string) => {
        const newCategory = { value: inputValue, label: inputValue };
        setMmtCategoriesOptions(prevOptions => [...prevOptions, newCategory]);
        setFormData(prevState => ({
            ...prevState,
            mmt_categories: [...prevState.mmt_categories, newCategory]
        }));
    };

    const handleCreateNewTpkenMMTCategory = (inputValue: string) => {
        const newCategory = { value: inputValue, label: inputValue };
        setMmtCategoriesOptions(prevOptions => [...prevOptions, newCategory]);
        setNewTokenData(prevState => ({
            ...prevState,
            mmt_categories: [...prevState.mmt_categories, newCategory]
        }));
    };


    const handleCreateEcosystem = (inputValue: string) => {
        const newEcosystem = { value: inputValue, label: inputValue };
        setEcosystemsOptions(prevOptions => [...prevOptions, newEcosystem]);
        setFormData(prevState => ({
            ...prevState,
            ecosystem: [...prevState.ecosystem, newEcosystem]
        }));
    };

    const handleCreateNewTokenEcosystem = (inputValue: string) => {
        const newEcosystem = { value: inputValue, label: inputValue };
        setEcosystemsOptions(prevOptions => [...prevOptions, newEcosystem]);
        setNewTokenData(prevState => ({
            ...prevState,
            ecosystem: [...prevState.ecosystem, newEcosystem]
        }));
    };


    const handleSaveChanges = (formData: any) => {


        if (selectedToken) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}admin/ticker/${selectedToken.id}?wallet_id=${address}`;

            // Transform the categories array
            const transformedCategories = formData.categories.map((category: Category) => category.value);
            // const transformedEcosystems = formData.ecosystem.map((ecosystem: Category) => ecosystem.value);
            const transformedMMTCategories = formData.mmt_categories.map((mmt_category: Category) => mmt_category.value);

            const payload = {
                ...formData,
                categories: transformedCategories,
                ecosystem: ecosystems,
                mmt_categories: transformedMMTCategories
            };


            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowTickerUpdateModal(false);
                    toast.success('Ticker updated successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff'
                        }
                    });
                    get_all_token(currentPage, filteredTokens, filteredKeywords, '');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to update ticker', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    } else {
                        toast.error('Failed to update ticker', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    }
                });
        }
    };


    const handleCreateNewToken = (formData: any) => {


        if (selectedToken) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}admin/ticker/create?wallet_id=${address}`;

            // Transform the categories array
            const transformedCategories = formData.categories.map((category: Category) => category.value);
            const transformedEcosystems = formData.ecosystem.map((ecosystem: Category) => ecosystem.value);
            const transformedMMTCategories = formData.mmt_categories.map((mmt_category: Category) => mmt_category.value);

            const payload = {
                ...formData,
                categories: transformedCategories,
                ecosystem: transformedEcosystems,
                mmt_categories: transformedMMTCategories
            };



            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowTickerCreateModal(false);
                    toast.success('Ticker created successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff'
                        }
                    });
                    get_all_token(currentPage, filteredTokens, filteredKeywords, '');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to create ticker', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    } else {
                        toast.error('Failed to create ticker', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    }
                });
        }
    };



    const get_all_token_filter = async (timeWindow: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=" + timeWindow, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();


                setKeywordFilter(data['filters']['keywords']);
                const categoriesWithTokens = transformTokensToCategories(data['filters']['tokens']);

                // Set the state with the formatted data
                setTokenFilter(categoriesWithTokens);
                const mmtoptions = data['filters']['mmt_categories'].map((mmt_category: any) => ({
                    value: mmt_category,
                    label: mmt_category
                }));
                setMmtCategoriesOptions(mmtoptions)

                setEcosystemFilter(data['filters']['ecosystem']);
                setCategoryFilter(data['filters']['mmt_categories']);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_all_token = async (page: any, token: any, keyword: any, category: any) => {
        setAllTweetloader(true);
        setAllToken([]);
        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';
        const searchQueryParam = debouncedSearchQuery.length > 0 ? `&token=${debouncedSearchQuery}` : '';
        // Determine the verifiedParam based on verificationStatus
        let verifiedParam = '';
        if (verificationStatus === 'verified') {
            verifiedParam = '&verified=True';
        } else if (verificationStatus === 'not_verified') {
            verifiedParam = '&verified=False';
        }

        let fetchedParam = '';
        if (fetchStatus === 'fetched') {
            fetchedParam = '&fetch_status=True';
        } else if (fetchStatus === 'not_fetched') {
            fetchedParam = '&fetch_status=False';
        }

        const url = `${process.env.REACT_APP_API_URL}admin/ticker?page=${page}&wallet_id=${walletIdParam}${searchQueryParam}${verifiedParam}${fetchedParam}`;

        const requestBody = {
            category: category ? category.join(',') : null,
            token: token ? token.join(',') : null,
            keyword: keyword ? keyword.join(',') : null,
            mmt_categories: filteredCategories ? filteredCategories.join(',') : null,
            ecosystem: filteredEcosystems ? filteredEcosystems.join(',') : null
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
                body: JSON.stringify({ ...requestBody }),
            });

            if (response.ok) {
                const data = await response.json();
                setAllToken(data['data']);
                setTotalPages(data['paging']['total_pages']);
                setCurrentPage(data['paging']['current_page']);
            } else {

                setAllToken([]);
                setTotalPages(0);
            }
        } catch (e) {
            console.log(e);
            setAllTweetloader(false);
            setAllToken([]);
            setTotalPages(0);
        } finally {
            setAllTweetloader(false);
        }
    };

    const fetchCategories = async () => {
        const url = `${process.env.REACT_APP_API_URL}admin/ticker/categories?wallet_id=${address}`;

        try {
            const response = await fetch(url, {
                method: 'GET'
            });

            if (response.ok) {
                const data = await response.json();
                const catoptions = data.data['categories'].map((category: any) => ({
                    value: category,
                    label: category
                }));
                setCategoryOptions(catoptions);
                const ecooptions = data.data['ecosystems'].map((ecosystems: any) => ({
                    value: ecosystems,
                    label: ecosystems
                }));

                setEcosystemsOptions(ecooptions);
            } else {
                console.error('Error fetching categories:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            // Always show the first and last page numbers
            if (i === 1 || i === totalPages) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === 2 && currentPage > 3) {
                pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            // Show the current page and the two pages before and after it
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === totalPages - 1 && currentPage < totalPages - 2) {
                pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }
        return pages;
    };

    const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
    const [filteredEcosystems, setFilteredEcosystems] = useState<string[]>([]);

    const handleEcosystemSelectionChange = (selectedValues: string[]) => {
        setFilteredEcosystems(selectedValues)
        // setIsEcosystemFilterApplied(selectedValues.length > 0);
        // handleResetClick();
    };

    const handleCategorySelectionChange = (selectedValues: string[]) => {
        setFilteredCategories(selectedValues)
        // handleResetClick();
    };

    const handleVerifiedCheckboxChange = (status: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setVerificationStatus(status);
    };

    const handleFetchedCheckboxChange = (status: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setFetchStatus(status);
    };

    const handleSearch = async (query: any) => {
        setSearchQuery(query);
    }

    const clearSearch = () => {
        setSearchQuery('');
    };



    const toggleDescription = (tokenSymbol: string) => {
        setExpandedDescriptions(prevState => ({
            ...prevState,
            [tokenSymbol]: !prevState[tokenSymbol]
        }));
    };

    const createToken = async () => {
        const url = `${process.env.REACT_APP_API_URL}/admin/ticker/create?wallet_id=${address}`;
        const payload = {
            token_symbol: "",
            chain_id: "",
            dexscreen_url: "",
            price_usd: 0,
            token_address: "",
            token_name: "",
            token_sniffer_url: "",
            image_url: "",
            coin_id: "",
            coingecko_url: "",
            description: "",
            categories: []
        };

        const headers = {
            'Content-Type': 'application/json',
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(payload)
            });
            const data = await response.json();
        } catch (error) {
            console.error('Error making API call:', error);
        }
    };


    const getCoingeckoAndDexscreenData = async (platform: any, platform_id: any) => {
        const url = `${process.env.REACT_APP_API_URL}admin/ticker-checker?wallet_id=${address}&platform=${platform}&platform_id=${platform_id}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
            });

            if (response.ok) {
                const data = await response.json();


                setFormData((prevFormData) => {
                    let update_data: Partial<FormData> = {};

                    if (data.data.token_name && data.data.token_name !== null) update_data.token_name = data.data.token_name;
                    if (data.data.coin_id && data.data.coin_id !== null) update_data.coin_id = data.data.coin_id;
                    if (data.data.image_url && data.data.image_url !== null) update_data.image_url = data.data.image_url;
                    if (data.data.token_symbol && data.data.token_symbol !== null) update_data.token_symbol = data.data.token_symbol;
                    if (data.data.description && data.data.description !== null) update_data.description = data.data.description;
                    if (data.data.website && data.data.website !== null) update_data.website = data.data.website;
                    if (data.data.telegram_channel_identifier && data.data.telegram_channel_identifier !== null) update_data.telegram_channel_identifier = data.data.telegram_channel_identifier;
                    if (data.data.twitter_screen_name && data.data.twitter_screen_name !== null) update_data.twitter_screen_name = data.data.twitter_screen_name;
                    if (data.data.coingecko_url && data.data.coingecko_url !== null) update_data.coingecko_url = data.data.coingecko_url;
                    if (data.data.asset_platform_id && data.data.asset_platform_id !== null) update_data.asset_platform_id = data.data.asset_platform_id;


                    // Map categories to the expected format
                    if (data.data.categories && data.data.categories.length > 0 && data.data.categories !== null) {
                        update_data.categories = data.data.categories.map((cat: string) => ({ value: cat, label: cat }));
                    }


                    if (data.data.ecosystem && data.data.ecosystem.length > 0 && data.data.ecosystem !== null) {
                        setEcosystems(data.data.ecosystem)
                    }

                    // Adding the additional fields
                    if (data.data.token_address && data.data.token_address !== null) update_data.token_address = data.data.token_address;
                    if (data.data.chain_id && data.data.chain_id !== null) update_data.chain_id = data.data.chain_id;
                    if (data.data.dexscreen_url && data.data.dexscreen_url !== null) update_data.dexscreen_url = data.data.dexscreen_url;

                    update_data.verified = data.data.verified !== null ? data.data.verified : prevFormData.verified;
                    update_data.fetch_status = data.data.fetch_status !== null ? data.data.fetch_status : prevFormData.fetch_status;

                    toast.success('Data successfully fetched', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff'
                        }
                    });


                    // Return the merged object
                    return {
                        ...prevFormData,
                        ...update_data
                    };
                });

            } else {
                console.error('Error fetching data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchAllDexscreenData = async () => {
        for (let ecosystem of ecosystems) {
            if (ecosystem.address) {
                const url = `${process.env.REACT_APP_API_URL}admin/ticker-checker?wallet_id=${address}&platform=ds&platform_id=${ecosystem.address}`;
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    if (!response.ok) {
                        // Handle 500 or other server errors
                        throw new Error(`Server error: ${response.status}`);
                    }

                    const data = await response.json();
                    if (!data || !data.data) {
                        // Handle unexpected data structure
                        throw new Error('Unexpected API response structure');
                    }

                    
                    setEcosystems(prevEcosystems =>
                        prevEcosystems.map(e =>
                            e.address === ecosystem.address
                                ? { ...e, dexscreen_url: data.data.dexscreen_url || e.dexscreen_url }
                                : e
                        )
                    );

                    toast.success('Data successfully fetched for ' + ecosystem.name, {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff'
                        }
                    });

                    // You can handle the fetched data here (e.g., update the ecosystem with the fetched data)
                } catch (error) {
                    console.error(`Error fetching data for ${ecosystem.name}:`, error);
                    setEcosystems(prevEcosystems =>
                        prevEcosystems.map(e =>
                            e.address === ecosystem.address
                                ? { ...e, dexscreen_url: '' }
                                : e
                        )
                    );
                }
            }
        }
    };

    const handleAddEcosystem = () => {
        setEcosystems([...ecosystems, { name: '', address: '', dexscreen_url: '' }]);
    };

    const handleDeleteEcosystem = (index: any) => {
        const updatedEcosystems = ecosystems.filter((_, i) => i !== index);
        setEcosystems(updatedEcosystems);
    };

    useEffect(() => {
        get_all_token(currentPage, filteredTokens, filteredKeywords, '');
    }, [currentPage, filteredTokens, filteredKeywords, debouncedSearchQuery, verificationStatus, fetchStatus, filteredCategories, filteredEcosystems]);


    useEffect(() => {
        get_all_token_filter('1month');
        fetchCategories();
    }, []);


    useEffect(() => {
        // Parse JSON data and create options for react-select
        const parsedOptions = jsonData.map((item) => ({
            value: item.coingecko_asset_platform_id,
            label: item.coingecko_asset_platform_id,
        }));
        setAssetPlatformIdOptions(parsedOptions);
    }, []);
    return (
        <div className="container-fluid">
            <Toaster />
            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">
                    <Row>

                        <Col sm={12} className="mt-3">
                            <Card className="t-w-card">
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <div className="filter-label">Filter by Ecosystem</div>
                                            <EcoSystemFilter ecosystems={ecosystemFilter} onSelectionChange={handleEcosystemSelectionChange} />
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <div className="filter-label">Filter by Category</div>
                                            <CategoryFilter categories={categoryFilter} onSelectionChange={handleCategorySelectionChange} />
                                        </Col>
                                        <Col sm={12} md={4}>

                                            <div className="filter-label">Search by Token</div>
                                            <div className="admin-search-bar-container mt-3" ref={searchBarRef}>
                                                <div className="search-bar-input-wrapper">
                                                    <input
                                                        type="text"
                                                        placeholder="Search Token"
                                                        value={searchQuery}
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                    />
                                                    {searchQuery && (
                                                        <Icon.XCircle className="clear-icon" onClick={clearSearch} />
                                                    )}
                                                </div>

                                            </div>
                                            <div className='ctw-btn mt-2' onClick={handleCreateNewTokenClick}>
                                                Create New Token
                                            </div>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <div className="mt-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="All (Verfied & Not Verified)"
                                                    className="watchlist-checkbox"
                                                    checked={verificationStatus === "all"}
                                                    onChange={() => handleVerifiedCheckboxChange("all")}
                                                    disabled={!isConnected}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Verified Only"
                                                    className="watchlist-checkbox"
                                                    checked={verificationStatus === "verified"}
                                                    onChange={() => handleVerifiedCheckboxChange("verified")}
                                                    disabled={!isConnected}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Not Verified"
                                                    className="watchlist-checkbox"
                                                    checked={verificationStatus === "not_verified"}
                                                    onChange={() => handleVerifiedCheckboxChange("not_verified")}
                                                    disabled={!isConnected}
                                                />
                                            </div>
                                            <div>
                                                <div className='notes'>
                                                    <Icon.CheckCircleFill className='verified'></Icon.CheckCircleFill> -  Means Verified by admin
                                                </div>
                                                <div className='notes'>
                                                    <Icon.XCircleFill className='not-verified'></Icon.XCircleFill> - Means Not Verified by admin
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm={12} md={4}>
                                            <div className="mt-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="All (Feched & Not Fetched)"
                                                    className="watchlist-checkbox"
                                                    checked={fetchStatus === "all"}
                                                    onChange={() => handleFetchedCheckboxChange("all")}
                                                    disabled={!isConnected}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Fetched Only"
                                                    className="watchlist-checkbox"
                                                    checked={fetchStatus === "fetched"}
                                                    onChange={() => handleFetchedCheckboxChange("fetched")}
                                                    disabled={!isConnected}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Not Fetched"
                                                    className="watchlist-checkbox"
                                                    checked={fetchStatus === "not_fetched"}
                                                    onChange={() => handleFetchedCheckboxChange("not_fetched")}
                                                    disabled={!isConnected}
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={12} className='mt-3'>

                            {allTweetloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!allTweetloader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Tokens</th>
                                                    <th>Coin id</th>
                                                    <th>Description</th>
                                                    <th> Categories</th>
                                                    <th> Fetch Api</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allToken.length > 0 ? (
                                                    allToken.map((tokenTweets, index) => (
                                                        <tr key={index} className={allToken.length === 1 ? 'custom-single-row-height' : ''}>
                                                            <td width={'2%'}>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    {index + 1}
                                                                    {tokenTweets['verified'] ? (
                                                                        <Icon.CheckCircleFill className='verified'></Icon.CheckCircleFill> // Tick symbol for verified
                                                                    ) : (
                                                                        <Icon.XCircleFill className='not-verified'></Icon.XCircleFill> // Cross symbol for not verified
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td width={'25%'} className='cursor-pointer' onClick={() => open_token_detail(tokenTweets['id'])}>
                                                                <div className='d-flex align-items-center'>
                                                                    <div>
                                                                        <img src={tokenTweets['image_url'] ? tokenTweets['image_url'] : no_img} className="ticker-img" />
                                                                    </div>
                                                                    <div className="ms-1 ticker-name">{tokenTweets['token_name'] ? tokenTweets['token_name'] : tokenTweets['cryptocurrency']} </div>
                                                                    <div className="ms-1 ticker-symbol">{tokenTweets['token_symbol']}</div>
                                                                </div>
                                                            </td>
                                                            <td width={'5%'}>{tokenTweets['coin_id']}</td>
                                                            <td width={'18%'}>
                                                                {tokenTweets.description && tokenTweets.token_symbol && (
                                                                    <>
                                                                        {expandedDescriptions[tokenTweets.token_symbol]
                                                                            ? tokenTweets.description
                                                                            : `${tokenTweets.description.substring(0, 100)}...`}
                                                                        <span
                                                                            className='cursor-pointer text-primary ms-1'
                                                                            onClick={() => toggleDescription(tokenTweets.token_symbol ?? '')}
                                                                        >
                                                                            {expandedDescriptions[tokenTweets.token_symbol] ? 'Read Less' : 'Read More'}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td width={'35%'}>
                                                                {tokenTweets['categories'] ?
                                                                    (Array.isArray(tokenTweets['categories'])
                                                                        ? tokenTweets['categories'].join(', ')
                                                                        : typeof tokenTweets['categories'] === 'string'
                                                                            ? (tokenTweets['categories'] as string).split(',').map(category => category.trim()).join(', ')
                                                                            : ''
                                                                    )
                                                                    : ''
                                                                }
                                                            </td>
                                                            <td width={'5%'}>
                                                                {tokenTweets['fetch_status'] ? (
                                                                    <span className="text-success">Fetched</span> // Fetched status
                                                                ) : (
                                                                    <span className="text-danger">Not Fetched</span> // Not fetched status
                                                                )}
                                                            </td>
                                                            <td width={'5%'}>
                                                                <div className="ctw-btn" onClick={() => edit_token(tokenTweets)}>
                                                                    Edit
                                                                </div>
                                                            </td>
                                                            <td width={'5%'}>
                                                                <div className="ctw-btn" onClick={() => delete_token(tokenTweets)}>
                                                                    Delete
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div>
                                        {totalPages > 1 &&
                                            <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                <ul className="pagination">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href="#" aria-label="Previous">
                                                                <span aria-hidden="true">Previous</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {renderPageNumbers()}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href="#" aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        }
                                    </div>

                                    <Modal className='tweet-modal modal-lg' show={showTickerUpdateModal} onHide={() => setShowTickerUpdateModal(false)} centered>
                                        <Modal.Header className="justify-content-between">
                                            <div className='update-modal-heading'>Edit Token</div>
                                            <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTickerUpdateModal(false)}></Icon.XCircle>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className='token-description-label' htmlFor="token_name">Token Name:</label>
                                                    <input
                                                        id="token_name"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.token_name}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="token_symbol">Token Symbol:</label>
                                                    <input
                                                        id="token_symbol"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.token_symbol}
                                                        onChange={handleChange}
                                                    />
                                                    <div className='d-flex align-items-center mt-2'>
                                                        <label className='token-description-label' htmlFor="coin_id">
                                                            Coin ID (Coingecko api id):
                                                        </label>
                                                        {formData.coin_id && formData.coin_id != null &&
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-secondary ms-2"
                                                                onClick={() => getCoingeckoAndDexscreenData("cg", formData.coin_id)}
                                                            >
                                                                Fetch Data
                                                            </button>
                                                        }
                                                    </div>
                                                    <div className='notes'>* if data don't want fetch from api please add $MMT</div>
                                                    <input
                                                        id="coin_id"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.coin_id}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="chain_id">Chain ID (Dexscreener api id):</label>
                                                    <input
                                                        id="chain_id"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.chain_id}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="asset_platform_id">Asset Platform ID (Geckoterminal api id):</label>
                                                    <input
                                                        id="asset_platform_id"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.asset_platform_id}
                                                        onChange={handleChange}
                                                    />
                                                    {/* <Select
                                                        id="asset_platform_id"
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                        value={assetPlatformIdOptions.find((option: any) => option.value === formData.asset_platform_id)}
                                                        onChange={handleSelectChange}
                                                        options={assetPlatformIdOptions}
                                                    /> */}
                                                    {/* <CreatableSelect
                                                        id="asset_platform_id"
                                                        value={assetPlatformIdOptions.find((option: any) => option.value === formData.asset_platform_id)}
                                                        onChange={handleSelectChange}
                                                        onCreateOption={handleCreateAssetPlatformId}
                                                        options={assetPlatformIdOptions}
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    /> */}

                                                    <label className='token-description-label mt-2' htmlFor="image_url">Image URL:</label>
                                                    <input
                                                        id="image_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.image_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="description">Token Description:</label>
                                                    <textarea
                                                        id="description"
                                                        className="form-control token-description-text-area mt-3"
                                                        value={formData.description}
                                                        onChange={handleChange}
                                                        style={{ height: '150px' }}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="token_address">Token Address:</label>
                                                    <input
                                                        id="token_address"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.token_address}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="dappradar_url">Dappradar Url:</label>
                                                    <input
                                                        id="dappradar_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.dappradar_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="defillama_url">Defillama Url:</label>
                                                    <input
                                                        id="defillama_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.defillama_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="discord_url">Discord Url:</label>
                                                    <input
                                                        id="discord_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.discord_url}
                                                        onChange={handleChange}
                                                    />

                                                </div>
                                                <div className="col-md-6">

                                                    <label className='token-description-label mt-2' htmlFor="tokenterminal_url">Tokenterminal Url:</label>
                                                    <input
                                                        id="tokenterminal_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.tokenterminal_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="website">Website:</label>
                                                    <input
                                                        id="website"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.website}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="telegram_channel_identifier">Telegram Channel Identifier:</label>
                                                    <input
                                                        id="telegram_channel_identifier"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.telegram_channel_identifier}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="twitter_screen_name">Twitter Screen Name:</label>
                                                    <input
                                                        id="twitter_screen_name"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.twitter_screen_name}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label' htmlFor="coingecko_url">Coingecko URL:</label>
                                                    <input
                                                        id="coingecko_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.coingecko_url}
                                                        onChange={handleChange}
                                                    />
                                                    <div className='d-flex align-items-center mt-2'>
                                                        <label className='token-description-label mt-2' htmlFor="dexscreen_url">Dexscreen URL:</label>
                                                        {formData.token_address && formData.token_address != null &&
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-secondary ms-2"
                                                                onClick={() => getCoingeckoAndDexscreenData("ds", formData.token_address)}
                                                            >
                                                                Fetch Data
                                                            </button>
                                                        }
                                                    </div>


                                                    <input
                                                        id="dexscreen_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formData.dexscreen_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="categories">Coingecko Categories:</label>
                                                    <CreatableSelect
                                                        id="categories"
                                                        value={formData.categories}
                                                        onChange={handleCategoryChange}
                                                        onCreateOption={handleCreateCategory}
                                                        options={categoryOptions}
                                                        isMulti
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="mmt_categories">MMT Categories:</label>
                                                    <CreatableSelect
                                                        id="mmt_categories"
                                                        value={formData.mmt_categories}
                                                        onChange={handleMMTCategoryChange}
                                                        onCreateOption={handleCreateMMTCategory}
                                                        options={mmtCategoriesOptions}
                                                        isMulti
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    />
                                                    {/* <label className='token-description-label mt-2' htmlFor="ecosystem">Ecosystems:</label>
                                                    <CreatableSelect
                                                        id="ecosystem"
                                                        value={formData.ecosystem}
                                                        onChange={handleEcosystemChange}
                                                        onCreateOption={handleCreateEcosystem}
                                                        options={ecosystemsOptions}
                                                        isMulti
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    /> */}
                                                    <label className='token-description-label mt-3'>Verified:</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="verified"
                                                            id="verified-true"
                                                            value="true"
                                                            checked={formData.verified === true}
                                                            onChange={() => setFormData({ ...formData, verified: true })}
                                                        />
                                                        <label className="token-description-label" htmlFor="verified-true">
                                                            True
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="verified"
                                                            id="verified-false"
                                                            value="false"
                                                            checked={formData.verified === false}
                                                            onChange={() => setFormData({ ...formData, verified: false })}
                                                        />
                                                        <label className="token-description-label" htmlFor="verified-false">
                                                            False
                                                        </label>
                                                    </div>
                                                    <label className='token-description-label mt-3'>Fetch Status:</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="fetch_status"
                                                            id="fetch_status-true"
                                                            value="true"
                                                            checked={formData.fetch_status === true}
                                                            onChange={() => setFormData({ ...formData, fetch_status: true })}
                                                        />
                                                        <label className="token-description-label" htmlFor="fetch_status-true">
                                                            True
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="fetch_status"
                                                            id="fetch_status-false"
                                                            value="false"
                                                            checked={formData.fetch_status === false}
                                                            onChange={() => setFormData({ ...formData, fetch_status: false })}
                                                        />
                                                        <label className="token-description-label" htmlFor="fetch_status-false">
                                                            False
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <label className='token-description-label mt-2'>Ecosystems:</label>
                                                    <div className='ecosystem-table'>
                                                        <Table className='atw-table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Name</th>
                                                                    <th>Address</th>
                                                                    <th>Dex Url</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ecosystems.length > 0 ? (
                                                                    ecosystems.map((ecosystem, index) => {

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td width={'25%'}>
                                                                                    <Select
                                                                                        id={`ecosystem_select_${index}`}
                                                                                        className="admin-select-container mt-2"
                                                                                        classNamePrefix="admin-select"
                                                                                        value={{ value: ecosystem.name, label: ecosystem.name }}
                                                                                        options={ecosystemsOptions}

                                                                                        onChange={(selectedOption) => handleEcosystemChangeInTable(index, 'name', selectedOption)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        id={`eco_address_${index}`}
                                                                                        className="form-control mt-2 ticker-text-box"
                                                                                        name="address"
                                                                                        value={ecosystem.address || ''}
                                                                                        onChange={(e) => handleEcosystemChange(index, 'address', { value: e.target.value })}                                                                                    
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        id={`dex_url_${index}`}
                                                                                        className="form-control mt-2 ticker-text-box"
                                                                                        name="dexscreen_url"
                                                                                        value={ecosystem.dexscreen_url || ''}
                                                                                        onChange={(e) => handleEcosystemChange(index, 'dexscreen_url', { value: e.target.value })}                                                                                        
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        className="btn btn-sm btn-danger"
                                                                                        onClick={() => handleDeleteEcosystem(index)}
                                                                                    >
                                                                                        Delete
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={5} className="text-center">
                                                                            No ecosystems available
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>

                                                        <div className="mt-3 text-right">
                                                            {ecosystems.length > 0 &&
                                                                <button
                                                                    className="btn btn-sm btn-primary ms-2"
                                                                    onClick={fetchAllDexscreenData}
                                                                >
                                                                    Fetch All
                                                                </button>
                                                            }
                                                            <button
                                                                className="btn btn-sm btn-secondary ms-2"
                                                                onClick={handleAddEcosystem}
                                                            >
                                                                Add New Ecosystem
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </Modal.Body>

                                        <Modal.Footer>
                                            <button className="btn btn-primary" onClick={handleSave} disabled={loading}>
                                                {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => setShowTickerUpdateModal(false)}>Close</button>
                                        </Modal.Footer>
                                    </Modal>


                                    <Modal className='tweet-modal modal-lg' show={showTickerCreateModal} onHide={() => setShowTickerCreateModal(false)} centered>
                                        <Modal.Header className="justify-content-between">
                                            <div className='update-modal-heading'>Create New Token</div>
                                            <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTickerCreateModal(false)}></Icon.XCircle>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {/* Add input fields and other UI components here */}
                                                    <label className="token-description-label">Token Name:</label>
                                                    <input
                                                        id="token_name"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.token_name}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="token_symbol">Token Symbol:</label>
                                                    <input
                                                        id="token_symbol"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.token_symbol}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="coin_id">Coin ID (Coingecko api id):</label>
                                                    <div className='notes'>* if data don't want fetch from api please add $MMT</div>
                                                    <input
                                                        id="coin_id"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.coin_id}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="chain_id">Chain ID (Dexscreener api id):</label>
                                                    <input
                                                        id="chain_id"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.chain_id}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="asset_platform_id">Asset Platform ID (Geckoterminal api id):</label>
                                                    {/* <Select
                                                        id="asset_platform_id"
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                        value={assetPlatformIdOptions.find((option: any) => option.value === formData.asset_platform_id)}
                                                        onChange={handleSelectChange}
                                                        options={assetPlatformIdOptions}
                                                    /> */}
                                                    <CreatableSelect
                                                        id="asset_platform_id"
                                                        value={assetPlatformIdOptions.find((option: any) => option.value === newTokenData.asset_platform_id)}
                                                        onChange={handleNewTokenSelectChange}
                                                        onCreateOption={handleCreateNewTokenAssetPlatformId}
                                                        options={assetPlatformIdOptions}
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    />

                                                    <label className='token-description-label mt-2' htmlFor="image_url">Image URL:</label>
                                                    <input
                                                        id="image_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.image_url}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="description">Token Description:</label>
                                                    <textarea
                                                        id="description"
                                                        className="form-control token-description-text-area mt-3"
                                                        value={newTokenData.description}
                                                        onChange={handleNewTokenChange}
                                                        style={{ height: '150px' }}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="token_address">Token Address:</label>
                                                    <input
                                                        id="token_address"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.token_address}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="dappradar_url">Dappradar Url:</label>
                                                    <input
                                                        id="dappradar_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.dappradar_url}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="defillama_url">Defillama Url:</label>
                                                    <input
                                                        id="defillama_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.defillama_url}
                                                        onChange={handleNewTokenChange}
                                                    />


                                                </div>
                                                <div className="col-md-6">
                                                    <label className='token-description-label mt-2' htmlFor="discord_url">Discord Url:</label>
                                                    <input
                                                        id="discord_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.discord_url}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="tokenterminal_url">Tokenterminal Url:</label>
                                                    <input
                                                        id="tokenterminal_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.tokenterminal_url}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="website">Website:</label>
                                                    <input
                                                        id="website"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.website}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="telegram_channel_identifier">Telegram Channel Identifier:</label>
                                                    <input
                                                        id="telegram_channel_identifier"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.telegram_channel_identifier}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="twitter_screen_name">Twitter Screen Name:</label>
                                                    <input
                                                        id="twitter_screen_name"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.twitter_screen_name}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label' htmlFor="coingecko_url">Coingecko URL:</label>
                                                    <input
                                                        id="coingecko_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.coingecko_url}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="dexscreen_url">Dexscreen URL:</label>
                                                    <input
                                                        id="dexscreen_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={newTokenData.dexscreen_url}
                                                        onChange={handleNewTokenChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="categories">Coingecko Categories:</label>
                                                    <CreatableSelect
                                                        id="categories"
                                                        value={newTokenData.categories}
                                                        onChange={handleNewTokenCategoryChange}
                                                        onCreateOption={handleCreateNewTokenCategory}
                                                        options={categoryOptions}
                                                        isMulti
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="mmt_categories">MMT Categories:</label>
                                                    <CreatableSelect
                                                        id="mmt_categories"
                                                        value={newTokenData.mmt_categories}
                                                        onChange={handleNewTokenMMTCategoryChange}
                                                        onCreateOption={handleCreateNewTpkenMMTCategory}
                                                        options={mmtCategoriesOptions}
                                                        isMulti
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="ecosystem">Ecosystems:</label>
                                                    <CreatableSelect
                                                        id="ecosystem"
                                                        value={newTokenData.ecosystem}
                                                        onChange={handleNewTokenEcosystemChange}
                                                        onCreateOption={handleCreateNewTokenEcosystem}
                                                        options={ecosystemsOptions}
                                                        isMulti
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    />
                                                    <label className='token-description-label mt-3'>Verified:</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="verified"
                                                            id="verified-true"
                                                            value="true"
                                                            checked={newTokenData.verified === true}
                                                            onChange={() => setNewTokenData({ ...newTokenData, verified: true })}
                                                        />
                                                        <label className="token-description-label" htmlFor="verified-true">
                                                            True
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="verified"
                                                            id="verified-false"
                                                            value="false"
                                                            checked={newTokenData.verified === false}
                                                            onChange={() => setNewTokenData({ ...newTokenData, verified: false })}
                                                        />
                                                        <label className="token-description-label" htmlFor="verified-false">
                                                            False
                                                        </label>
                                                    </div>
                                                    <label className='token-description-label mt-3'>Fetch Status:</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="fetch_status"
                                                            id="fetch_status-true"
                                                            value="true"
                                                            checked={newTokenData.fetch_status === true}
                                                            onChange={() => setNewTokenData({ ...newTokenData, fetch_status: true })}
                                                        />
                                                        <label className="token-description-label" htmlFor="fetch_status-true">
                                                            True
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="fetch_status"
                                                            id="fetch_status-false"
                                                            value="false"
                                                            checked={newTokenData.fetch_status === false}
                                                            onChange={() => setNewTokenData({ ...newTokenData, fetch_status: false })}
                                                        />
                                                        <label className="token-description-label" htmlFor="fetch_status-false">
                                                            False
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-primary" onClick={handleNewTokenCreate} disabled={loading}>
                                                {loading ? <Spinner animation="border" size="sm" /> : 'Create Token'}
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => setShowTickerCreateModal(false)}>Close</button>

                                        </Modal.Footer>
                                    </Modal>

                                </>}
                        </Col>
                    </Row>

                </div>
            </section>
        </div>
    )
}

export default TickerList;